import React from 'react';
import './WelcomeScreen.css'
import {IconQuestionMark, IconMail, IconShoppingCart, IconGlass} from '@tabler/icons-react'
import { Helmet } from 'react-helmet';

import 'material-symbols';


const WelcomeScreen = ({setWelcomeScreen}) => {

	const data = [
		{icon: 'question_mark', height: 'h-12', d_height: 'h-12', text: "Sie füllen unseren Fragebogen über Ihre Weinvorlieben aus.", id: 1 },
		{icon: 'mail', height: 'h-16', d_height: 'h-20', text: "Wir stellen Ihnen ein Weinpaket zusammen und senden Ihnen per Mail einen Link.", id: 2},
		{icon: 'shopping_cart', height: 'h-24', d_height: 'h-28', text: "Mit diesem Link landen Sie direkt in unserem Online-Shop. Dort sehen Sie einen individuell für Sie zusammengestellten Warenkorb. Diesen können Sie bestellen oder nach Belieben ändern.", id: 3},
		{icon: 'liquor', height: 'h-16', d_height: 'h-20', text: "Schon nach ein paar Tagen können Sie den Wein zu Hause geniessen. Wir freuen uns über ein Feedback zur getroffenen Auswahl.", id: 4}

	]
	return (
		<div className = "flex flex-row">
		<div className="flex flex-col items-center justify-center w-screen text-justify">
			<div className="grid sm:grid-cols-3 grid-cols-1 grid-flow-row gap-5  sm:h-[60vh]  xl:px-52">
				
			
				<div className='flex flex-row items-center justify-center col-span-1'>
					<img src="bottle-img.png" alt="" className='object-contain h-[300px]  sm:h-[600px]' />
				</div>
				<div className='sm:col-span-2 p-2 sm:block flex flex-col items-center'>
					<div className="text-3xl uppercase sm:m-3 mb-3 text-center sm:text-left">
						<span className='italic'>so</span><span className='font-bold'> geht's</span>
					</div>
					<div className="mt-10">
						{data.map((item, index) => (
							<div className="flex flex-row" key={item.id}>
								<div className="flex flex-col items-center">
									<div className='text-white bg-c-orange sm:p-2 p-2 rounded-full'>
										<span className="material-symbols-outlined sm:m-2 m-2">{item.icon}</span>
									</div>
									<div className={`bg-c-green w-2  sm:${item.d_height}  ${item.height} -z-10  -mt-1 ${index == data.length-1 && 'hidden'}`}>
									</div>
								</div>
								<div className='font-bold pl-5 sm:pt-5'>
									{item.text}
								</div>
							</div>
						))}
						
					</div>


					<button onClick={() => {setWelcomeScreen(false)}} className="rounded-lg bg-c-green font-bold p-3 text-2xl text-white  sm:ml-[85px] mt-10">Los Geht's!</button>
				</div>
			</div>

		</div>
		<div>
  <a href="https://adriane-moll.de/datenschutzerklaerung/">Datenschutz</a> <br />
  <a href="https://adriane-moll.de/impressum/">Impressum</a>
  </div>
		</div>
	);
}

export default WelcomeScreen;

export const surveyJson = {
 locale: "de",
 completedHtml: "Vielen Dank! <br> <br> In den nächsten Tagen erhalten Sie einen Link zu einen Warenkorb mit unseren Empfehlungen für Sie per E-mail. <br> <br>Wenn er Ihnen gefällt, können Sie diesen so in unserem Online-Shop bestellen oder nach Belieben verändern.<br><br>Ihre Familie Moll <br> <br><div><button class='back-to-shop-button'><a href='https://mollwein-shop.de'>Zurück zum Shop</a></button></div>",
 pagePrevText: "Zurück",
 pageNextText: "Weiter",
 completeText: "Absenden",
 pages: [
  {
   name: "Intro",
   elements: [
    {
     type: "checkbox",
     name: "Was darf ins Paket",
     title: "Was darf in Ihr Paket?",
     description: "Mehrauswahl möglich. Bitte mindestens eine Auswahl treffen.",
     isRequired: true,
     requiredErrorText: "Bitte mindestens eine Auswahl treffen.",
     choices: [
      "Rotweine",
      "Weissweine",
      {
       value: "Rose",
       text: "Rosé"
      },
      "Sekt",
      "Secco",
      {
       value: "Raritaeten",
       text: "Raritäten"
      }
     ],
     otherText: "Raritäten"
    }
   ],
   title: "Weinberatung"
  },
  {
   name: "Weissweine",
   elements: [
    {
     type: "checkbox",
     name: "Wie moegen Sie Ihren Weisswein",
     title: "Wie mögen Sie Ihren Weisswein?",
     description: "Mehrfachauswahl möglich",
     choices: [
      "Sehr trocken bis trocken",
      "Fruchtig-trocken bis feinherb",
      "Feinherb bis Halbtrocken",
      "Süss"
     ]
    },
    {
     type: "checkbox",
     name: "Ich bin auf der Suche nach (Weisswein)",
     title: "Ich bin auf der Suche nach (Weisswein)",
     description: "Mehrfachauswahl möglich",
     choices: [
      "offen für ALLES",
      {
       value: "duftig, sueffig, unkompliziert, Schlabberrababb",
       text: "duftig, süffig, unkompliziert, Schlabberrababb"
      },
      "elegant, klassisch, nicht zu blumig, passt zu vielen Gerichten",
      {
       value: "koerperreich, komplex, markant",
       text: "körperreich, komplex, markant"
      },
      "gerne auch Holzfass- und Barrique gereift",
      "Entdeckerweine, fordernd, speziell",
      {
       value: "maeßige Saeure ist mir wichtig",
       text: "mäßige Säure ist mir wichtig"
      }
     ],
     showOtherItem: true,
     otherText: "eigene Bemerkung"
    }
   ],
   visibleIf: "{Was darf ins Paket} contains 'Weissweine'",
   title: "Weissweine"
  },
  {
   name: "Rotweine",
   elements: [
    {
     type: "checkbox",
     name: "Wie moegen Sie Ihren Rotwein?",
     title: "Wie mögen Sie Ihren Rotwein?",
     description: "Mehrfachauswahl möglich",
     choices: [
      "Sehr trocken bis trocken",
      "Fruchtig-trocken bis feinherb",
      "Feinherb bis Halbtrocken",
      {
       value: "Suess",
       text: "Süss"
      }
     ]
    },
    {
     type: "checkbox",
     name: "Ich bin auf der Suche nach",
     visibleIf: "{Was darf ins Paket} contains 'Rotweine'",
     title: "Ich bin auf der Suche nach (Rotwein)",
     description: "Mehrfachauswahl möglich",
     choices: [
      "offen für ALLES",
      "fruchtig, beerig , süffig, unkompliziert, Schlabberrababb",
      "elegant, klassisch, passt zu vielen Gerichten",
      {
       value: "koerperreich, komplex, markant",
       text: "körperreich, komplex, markant"
      },
      "gerne auch Holzfass- und Barrique gereift",
      "Entdeckerweine, fordernd, speziell",
      {
       value: "maeßige Saeure ist mir wichtig",
       text: "mäßige Säure ist mir wichtig"
      }
     ],
     showOtherItem: true,
     otherText: "eigene Bemerkung"
    }
   ],
   visibleIf: "{Was darf ins Paket} contains 'Rotweine'",
   title: "Rotweine"
  },
  {
   name: "Rosé",
   elements: [
    {
     type: "checkbox",
     name: "Wie mögen Sie Ihren Rose",
     title: "Wie mögen Sie Ihren Rosé?",
     description: "Mehrfachauswahl möglich",
     choices: [
      "Fruchtig-trocken",
      "feinherb",
      "lieblich"
     ]
    }
   ],
   visibleIf: "{Was darf ins Paket} contains 'Rose'",
   title: "Rosé"
  },
  {
   name: "Allgemeine Infos",
   elements: [
    {
     type: "comment",
     name: "Anzahl Flaschen und Preislimit",
     title: "Anzahl Flaschen und Preislimit",
     description: "Ihre Flaschenanzahl sollte in folgende Paketgrössen passen: 6er, 12er, 15er und 18er. (gerne auch eine Kombination mehrerer Pakete)"
    },
    {
     type: "comment",
     name: "Das soll auf jeden Fall in meinem Paket sein",
     title: "Das soll auf jeden Fall in meinem Paket sein",
     description: "Angaben konkret wie Art.-Nr. und Menge oder Rebsorte, Weinkategorien wie Barrique-Wein, Burgunder etc."
    },
    {
     type: "comment",
     name: "Das darf auf keinen Fall in meinem Paket sein",
     title: "Das darf auf keinen Fall in meinem Paket sein"
    },
    {
     type: "comment",
     name: "Zusatzinfos",
     title: "Zusatzinfos",
     description: "z.B Ich suche Weine für eine Feier/Essenseinladung/Terrasse/Grillen etc."
    }
   ],
   title: "Allgemeine Wünsche"
  },
  {
   name: "Kontakt",
    elements: [
        {
         type: "text",
         name: "Vorname",
         title: "Vorname",
         isRequired: true,
         requiredErrorText: {
          de: "Das ist ein Pflichtfeld. Bitte ausfüllen."
         }
        },
        {
         type: "text",
         name: "Nachname",
         title: "Nachname",
         isRequired: true,
         requiredErrorText: {
          de: "Das ist ein Pflichtfeld. Bitte ausfüllen."
         }
        },
        {
         type: "text",
         name: "Mailadresse",
         title: "E-Mail Adresse",
         isRequired: true,
         requiredErrorText: {
          de: "Das ist ein Pflichtfeld. Bitte ausfüllen."
         },
         validators: [
          {
           type: "email",
           text: "Bitte eine korrekte E-Mail Adresse eingeben"
          }
         ]
        },
    {
     type: "text",
     name: "Telefonnummer",
     title: "Telefonnummer",
     validators: [
      {
       type: "numeric",
       text: "Bitte nur Zahlen eingeben"
      }
     ],
     maxLength: 15
    },
    {
     type: "html",
     name: "question2",
     html: {
      de: "<a href=\"https://adriane-moll.de/datenschutzerklaerung/\">  <button type='button'>Link zur Datenschutzerklärung</button></a>"
     }
    }, {
    type: "checkbox",
    name: "datenschutzcheckbox",
    title: {
     de: "Datenschutz"
    },
    isRequired: true,
    choices: [
     {
      value: "akzeptiert",
      text: {
       de: "Ich habe die Datenschutzerklärung gelesen und aktzeptiere diese."
      }
     }
    ]
   }   
],
   title: "Kontaktinfos"
  }
 ],
 //navigateToUrl: "https://adriane-moll.de/beratung-gesendet/"
}
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { surveyJson } from "./survey-data";
import { useCallback } from 'react';
import favicon from './favicon.png';
import { useState } from 'react';
import './App.css';
import WelcomeScreen from './WelcomeScreen';

function App() {
  const survey = new Model(surveyJson);


  async function postJSON(data) {
    try {
      const response = await fetch("https://weinberatung-jev53.ondigitalocean.app/api/v1/", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      });
      console.log(response)
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const surveyComplete = useCallback((sender) => {
    postJSON(sender.data)
    //document.location = "https://adriane-moll.de/beratung-gesendet/"
  }, []);

  survey.onComplete.add(surveyComplete);
  /*
  survey.onComplete.add(function (result) {
    document.location = "https://adriane-moll.de/beratung-gesendet/";
  });*/

  const [welcomeScreen, setWelcomeScreen] = useState(true);

  return (
    <div>
  <div>
      
      {welcomeScreen ? 
      <WelcomeScreen setWelcomeScreen={setWelcomeScreen} /> : 
      <>
      <div className='centered-div' >
        <img src={favicon}></img>
      </div>
      <Survey model={survey} />
      <a href="https://adriane-moll.de/datenschutzerklaerung/">Datenschutz</a> <br />
      <a href="https://adriane-moll.de/impressum/">Impressum</a>
        </>}


        
  </div>
  </div>
  );
}


export default App;
